<template>
    <div class="eps_img_Box">
        <div class="eps_main_img clearfix">
            <div class="minMain">
                <div class="eps_item_imgs" v-for="(item,index) in list" :key="index" v-show="imgIndex==index" @click="getOpenImg(item.value)" @mouseover="btnOpen" @mouseout="btnHide">
                    <img :src="item.imgUrl"  width="820" height="455" title="单击放大" />
                </div>
            </div>
        </div>
        <div class="btnMain" >
            <div class="eps_left" @click="leftClick">
                <span class="gwicon epsiconlefted"></span>
            </div>
            <div class="eps_right" @click="rightClick">
                <span class="gwicon epsiconrighted"></span>
            </div>
        </div>
        <div class="imgBlack" v-show="currentShow">
            <span class="imgClose" @click="currentShow=!currentShow">&times;</span>
            <div class="itemBlack" v-for="(item,index) in list" :key="index" >
                <img :src="item.imgUrl" v-show="currentIndex==item.value" />
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name:'eps',
    data(){
        return{
            currentIndex:null,
            currentShow:false,
            current:null,
            list: [
                {value:1, imgUrl: '/img/eps/p-1.png' },
                {value:2, imgUrl: '/img/eps/p-2.png' },
                {value:3, imgUrl: '/img/eps/p-3.png' },
                {value:4, imgUrl: '/img/eps/p-4.png' },
            ],
            imgIndex: 0,
            timer: null,
        }
    },
    methods:{
        autoPlay() {
            this.timer = setInterval(() => {
                this.imgIndex++;
                if (this.imgIndex > this.list.length - 1) {
                    this.imgIndex = 0;
                }
            },4000);
            
        },
        btnOpen() {
            clearInterval(this.timer);
        },
        btnHide() {
            this.autoPlay();
        },
        getOpenImg(value){
            var indexs = null;
            this.list.forEach((v)=>{
                if(value == v.value){
                    indexs = v.value;
                }
            });
            this.currentShow = true;
            this.currentIndex = indexs;
        },
        leftClick() {
            this.imgIndex--;
            if(this.imgIndex<0){
                this.imgIndex = 3;
            }
        },
        rightClick() {
            this.imgIndex++;
            if(this.imgIndex > 3) {
                this.imgIndex = 0;
            }
        }
    },
    mounted(){
        this.$nextTick(() => {
            this.autoPlay();
        });
    }
}
</script>
<style lang="scss">
.imgBlack{
    position: fixed;
    top:0;
    left:0;
    width:100%;
    height:100%;
    z-index:20;
    background:rgba(0,0,0,0.2);
    
}
.imgBlack .imgClose{
    cursor: pointer;
    position: fixed;
    right:3%;
    top:3%;
    z-index:20;
    display: inline-block;
    width:40px;
    height:40px;
    text-align: center;
    line-height:40px;
    color:#fff;
    font-size:40px;
}
.imgBlack .itemBlack{
    width:74%;
    height:80%;
    // max-height:80%;
    position: fixed;
    left: 50%;
    top: 50%;
    z-index:20;
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    
}
.imgBlack .itemBlack img{
    height:100%;
    width:100%;
}
.clearfix:after {
    content: " ";
    display: block;
    clear: both;
}

.eps_img_Box {
    position: relative;
    left:40px;
    width:820px;
    height:455px;
    margin-top:10px;
}

.eps_main_img {
    width:100%;
    height: 430px;
    position: absolute;
    top: 0px;
    left:0px;
    z-index:10;
    overflow: hidden;
    box-shadow: 1px 1px 10px 3px #dddddd;
    -webkit-box-shadow: 1px 1px 10px 3px #dddddd;
    -moz-box-shadow: 1px 1px 10px 3px #dddddd;
}
.minMain{
    width:100%;
    height:100%;
    
}
.eps_item_imgs {
    cursor: pointer;
    list-style: none;
    width:100%;
    height:455px;
}
.newItem {
    position: absolute;
    left: 0px;
    top: 0px;
}

.changeItem {
    position: absolute;
    left: 50%;
    top: 0px;
    margin-left: -202px;
    transform: scale(1.4);
}


.btnMain {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index:1;
}

.eps_left {
    position: absolute;
    left: -40px;
    top: 44%;
    margin-top: -15px;
    z-index: 10;
}

.eps_right {
    position: absolute;
    right: -40px;
    top: 44%;
    margin-top: -15px;
    z-index: 10;
}

.epsiconleft{
    cursor: pointer;
    height:32px;
    width:32px;
    background-position:-56px -422px;
}
.epsiconleft:hover{
    height: 32px;
    width: 32px;
    background-position:-56px -457px;
    transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -o-transform: rotate(180deg);
}
.epsiconlefted{
    cursor: pointer;
    height: 32px;
    width: 32px;
    background-position:-56px -457px;
    transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -o-transform: rotate(180deg);
}
.epsiconright {
    cursor: pointer;
    height:32px;
    width: 32px;
    background-position:-56px -422px;
    transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -o-transform: rotate(180deg);
}
.epsiconright:hover{
    height: 32px;
    width: 32px;
    background-position:-56px -457px;
    transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
}
.epsiconrighted{
    cursor: pointer;
    height: 32px;
    width: 32px;
    background-position:-56px -457px;
    transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
}
.wgiconleft{
    height:28px;
    width:28px;
    background-position:-56px -279px;
}
.wgiconleft:hover{
    height: 28px;
    width: 28px;
    background-position:-56px -312px;
    transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -o-transform: rotate(180deg);
}
.wgiconlefted{
    height: 28px;
    width: 28px;
    background-position:-56px -312px;
    transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -o-transform: rotate(180deg);
}
.wgiconright {
    height:28px;
    width: 28px;
    background-position:-56px -279px;
    transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -o-transform: rotate(180deg);
}
.wgiconright:hover{
    height: 28px;
    width: 28px;
    background-position: -56px -312px;
    transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
}
.wgiconrighted{
    height: 28px;
    width: 28px;
    background-position: -56px -312px;
    transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
}
.zuticonleft{
    height:32px;
    width:32px;
    background-position:-56px -346px;
}
.zuticonleft:hover{
    height: 32px;
    width: 32px;
    background-position:-56px -381px;
    transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -o-transform: rotate(180deg);
}
.zuticonlefted{
    height: 32px;
    width: 32px;
    background-position:-56px -381px;
    transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -o-transform: rotate(180deg);
}
.zuticonright{
    height:32px;
    width:32px;
    background-position:-56px -346px;
    transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -o-transform: rotate(180deg);
}
.zuticonright:hover{
    height: 32px;
    width: 32px;
    background-position:-56px -381px;
    transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
}
.zuticonrighted{
    height: 32px;
    width: 32px;
    background-position:-56px -381px;
    transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
}
</style>