<template>
    <!-- EPS数据库介绍信息 -->
    <div class="epsContent" v-html="html"></div>
</template>
<script>
import axios from "axios";
export default{
    name:'Vuetml',
    // 使用时请使用 :url.sync=""传值
    props: {
        url: {
            required: true
        }
    },
    data () {
        return {
            html: ''
        }
    },
    watch: {
        url (value) {
            this.load(value)
        }
    },
    mounted () {
        this.load(this.url);
    },
    methods: {        
        load (url) {
            if (url && url.length > 0) {
                // 加载中
                let param = {
                    accept: 'text/html, text/plain'
                }
                axios.get(url, param).then(res => {
                    this.html = res.data;
                }).catch(function(error) {
                    window.console.log(error);
                });
                
            }
        }
    }
}
</script>