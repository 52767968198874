<template>
    <div class="eps_img_Box">
        <div class="eps_main_img clearfix">
            <div class="minMain">
                <div class="eps_item_imgs" v-for="(item,index) in list" :key="index" v-show="imgIndex==index" @click="getOpenImg(item.value)" @mouseover="btnOpen" @mouseout="btnHide">
                    <img :src="item.imgUrl" width="820" height="455" title="单击放大" />
                </div>
            </div>
        </div>
        <div class="btnMain" >
            <div class="eps_left" @click="leftClick">
                <span class="gwicon epsiconlefted"></span>
            </div>
            <div class="eps_right" @click="rightClick">
                <span class="gwicon epsiconrighted"></span>
            </div>
        </div>
        <div class="imgBlack" v-show="currentShow">
            <span class="imgClose" @click="currentShow=!currentShow">&times;</span>
            <div class="itemBlack" v-for="(item,index) in list" :key="index" >
                <img :src="item.imgUrl" v-show="currentIndex==item.value" />
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name:'eps',
    data(){
        return{
            currentIndex:null,
            currentShow:false,
            current:null,
            list: [
                {value:1, imgUrl: '/img/qyjj/p-1.png' },
                {value:2, imgUrl: '/img/qyjj/p-2.png' },
                {value:3, imgUrl: '/img/qyjj/p-3.png' },
                {value:4, imgUrl: '/img/qyjj/p-4.png' },
                {value:5, imgUrl: '/img/qyjj/p-5.png' },
                {value:6, imgUrl: '/img/qyjj/p-6.png' },
            ],
            imgIndex: 0,
            timer: null,
        }
    },
    methods:{
        autoPlay() {
            this.timer = setInterval(() => {
                this.imgIndex++;
                if (this.imgIndex > this.list.length - 1) {
                    this.imgIndex = 0;
                }
            },4000);
            
        },
        btnOpen() {
            clearInterval(this.timer);
        },
        btnHide() {
            this.autoPlay();
        },
        getOpenImg(value){
            var indexs = null;
            this.list.forEach((v)=>{
                if(value == v.value){
                    indexs = v.value;
                }
            });
            this.currentShow = true;
            this.currentIndex = indexs;
        },
        leftClick() {
            this.imgIndex--;
            if(this.imgIndex<0){
                this.imgIndex = 5;
            }
        },
        rightClick() {
            this.imgIndex++;
            if(this.imgIndex > 5) {
                this.imgIndex = 0;
            }
        }
    },
    mounted(){
        this.$nextTick(() => {
            this.autoPlay();
        });
    }
}
</script>